export const faqP1Schema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is Turno.club and why should I buy my EV through Turno?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is a one stop destination to buy all best selling commercial electric vehicles available in the market. We help buyers to choose the best electric 3 wheeler vehicle for their business along with easy financing and assured resale.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does Turno have an exchange offer?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, Turno has an exchange offer where you can sell your old vehicle and get a new commercial electric 3 wheeler. To know more about our exchange offer, contact us on 08047842233.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the financial schemes Turno provides on three wheeler electric auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno gives the lowest downpayment of ₹49,999 on commercial electric vehicles with lowest interest rate of 12.49%. We also provide EV financing with low processing fee, zero pre-closure charges and zero hidden charges.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What happens after I pay ₹499 on the website to book my test drive?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "After you complete the online payment of ₹499 to book an electric vehicle, an exclusive Turno EV expert will be assigned to take you through further process and schedule a test drive. In case you change your mind, we will initiate the full refund of your booking fee.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I exchange my old vehicle for a new electric vehicle through Turno?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "At Turno, we do have a vehicle exchange offer. If you are interested in buying a new electric commercial vehicle from Turno, you can mention that to us while filling up the online form and we will take care of the rest.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the Turno warranty on an electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "We provide 3 years of warranty on the electric vehicle battery. Also, if you wish to sell your vehicle to Turno, we provide a guaranteed resale value up to ₹1.5 lakhs after 3 years on commercial electric three wheeler vehicles.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Will Turno help me get a Government subsidy on electric cargo vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Absolutely! The quotation from Turno includes subsidies and registration charges for EV. We also provide assistance in documentation to avail the electric vehicle subsidy.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How will I find the EV that suits my business?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno has partnered with the best-selling commercial vehicles because we understand that every business has different needs. You can contact our Turno EV experts to ensure that you buy an electric gadi that best fits your business.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How long will it take to deliver my EV once I pay the booking amount?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Generally, it takes us 5 days to deliver the vehicle after the booking amount is paid.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "I don’t know my credit score. Can I still get the financing?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "At Turno, we assure 100% on-road financing for electric vehicles. To know more about EV financing, kindly contact Turno EV experts today on 08047842233.\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the longest and shortest possible tenure of my EV loan?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "With Turno, you can avail EV financing for a tenure of 12 to 48 months. Turno, also offers on-road with no pre-closure or processing fee. We have no documentation or hidden charges.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
  ],
};

export const faqSchemaP2 = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "How to charge a three wheeler electric vehicle at home?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can charge your electric vehicle using a 15AMP socket. Such sockets are generally used for geysers and washing machines at home.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How much can a user save with a 3w electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "With electric vehicles, you can save on your daily expenses in running cost and the maintenance cost other than the subsidy received from the government. Running cost of EV - ₹1/km Maintenance cost of EV - ₹1000/year",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How much is the maintenance cost of a commercial electric 3 wheeler?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "On an average, the maintenance of an EV costs ₹1000/year. EVs have a much simpler engine with few moving parts that make the maintenance cost significantly low. What you need to take care of are- the brakes, steering, suspension, electric motor, and battery.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is range anxiety with commercial electric vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Range anxiety is the fear that the electric vehicles will not have enough battery to reach the destination and you will be stuck in remote places. Load electric vehicles have a good range up to 100+ km with full load. Also, you can monitor the battery range in real time in your Turno app.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Are electric vehicles safe for commercial use in India?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, electric vehicles are safe and the most economical option for commercial use in India.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How much time does it take to charge the electric vehicle and where can it be charged?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It takes 3 to 3.5 hours to fully charge three wheeler electric vehicles. You can charge your electric auto at home or at charging stations closest to you. The location information about charging stations for commercial electric vehicles is available on google maps as well.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Is there a subsidy available on electric commercial vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, subsidies are available on electric luggage vehicles. However, please be noted that it may vary from state to state. The subsidy is already included in the on-road price of the electric vehicles. For more information or to know the exact subsidy amount by vehicle model and location, contact us today.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I load up the electric vehicle beyond the load limit?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, you can increase the load beyond the limit but it is not recommended. In case you load more than the limit in the electric auto, the battery will discharge faster. So, we recommend following the instructions of the manufacturer.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Where do I service my three wheeler electric vehicle and what will it cost me?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Servicing can be done at the nearest electric vehicle showroom. on an average, the maintenance cost of EV is 1000/per year.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the standard features for a commercial electric auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "A commercial electric auto generally has a body, battery and motor. The commercial electric vehicles have a standard maximum speed of 50 kmph and have boost mode to temporarily increase the speed when the load is higher.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Is there an option to take a test drive of electric vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, you can opt for a test drive for an electric luggage auto at the vehicle showroom as well as a doorstep test drive is also available at your convenient time and location. Visit https://www.turno.club/vehicles to book a slot.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the range of an electric auto ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An electric luggage auto can go up to 100 km in one full charge. The range may vary depending on the vehicle variant as well as load carried on the electric vehicle. If the load is higher than the recommended limit, the battery may discharge faster than usual.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Is there an option to book a test drive before buying an electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, Turno provides test drives for three wheeler electric vehicles. You can contact Turno CEV experts to book a doorstep test drive at your convenient location and time or else you can visit the nearest showroom.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Do electric autos come with warranties?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, electric luggage vehicles come with battery warranty from vehicle manufacturers. Most of the commercial electric vehicles come with a warranty of 3 years or up to 80,000 km.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How can I enhance the driving range of my electric carrier auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Driving smoothly is the key to increasing the range of your vehicle. Avoiding sudden acceleration and maintaining the tires in good condition can improve the driving range of your electric car.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Will my EV auto lose charge when it’s parked?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An electric vehicle does not lose charge when it’s parked. If you park your vehicle for a longer duration, you may experience a battery drain by a few percent but will never run out of charge because of long parking time.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What kind of maintenance do electric vehicles need?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Electric cars require less maintenance compared to a fuel-powered vehicle. What you need to take care of are- the brakes, steering and suspension, the electric motor and the battery.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I drive my electric vehicle during rains?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, you can drive your electric vehicle during rains without any worries.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Are EVs safe?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "All EV batteries go through rigorous testing procedures at National Accreditation Board for Testing and Calibration Laboratories (NABL) certified labs. Certification agencies in India like ARAI, ICAT certify EV and chargers. Thus, it is safe to drive an EV.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does an electric vehicle work in cold weather ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cold weather may impact your battery range if it is 0 degree or lesser. Considering the weather conditions of India, you can be assured of no battery drainage due to cold weather.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Are electric autos more expensive than fuel-powered autos?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "No, electric autos and fuel-powered autos need a similar investment, which is nearly Rs. 2 lakhs. However, the total cost of owning an EV is less than half of owning a fuel-powered vehicle while calculated for three years. To know more about the total cost of owing, kindly contact Turno EV experts.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Should I consider buying insurance for my commercial electric auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "We highly recommend buying insurance for your EV as the Motor Vehicles Act, 1988, mentions that third-party insurance is compulsory. Having an electric commercial insurance policy will help you remain on the secure side of the law.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    //piaggio
    {
      "@type": "Question",
      name: "What is the recommended service interval for first service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The recommended service interval for first service is  3 months or 5000 kms",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does the manufacturer provide any free services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, all  Piaggio Ape Electrik buyers will get 10 free services.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How do I schedule servicing for my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The dealer will give a reminder call 2-5 days ahead of the scheduled service date.  We strongly recommend getting the servicing done on time as if you can not turn up, it will result in lapsing your free service.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I get Road Side Assistance(RSA) for my Piaggio Ape Electrik?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, RSA is available. To arrange the service, you can directly call the dealer or call us for a faster resolution.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the steps of servicing my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The steps of vehicle servicing are as follows Charge the battery up to one block and then check with them multimeter to see whether the cells are being charged enough. Check the breaks. Check if the break oil needs to be changed. Air pressure wash",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How long will it take for one servicing?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It may take a max of 8 hours for complete servicing. However, if there are any other issues with the vehicle, it may take longer.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },

    //Mahindra

    {
      "@type": "Question",
      name: "What is the recommended service interval for first service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The recommended service interval is 2000 kms or once in every 3 months.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does the manufacturer provide any free services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "First three services of your Mahindra Treo Zor along with the labor charges are free. After three servicings,  you will be charged for brake oil change and general battery check up.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How do I schedule servicing for my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "To book servicing of your vehicle, you can contact the service center directly. Otherwise, you can also reach out to the team and we can schedule it on your behalf.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I get Roadside Assistance(RSA) for my Mahindra Treo Zor?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, RSA is available. To arrange the service, you can directly call the dealer or call us for a faster resolution. Please note that the RSA services costs ₹1,260rs / year",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the steps of servicing my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The steps of vehicle servicing are as follows- Charge the battery up to one block and then check with them multimeter to see whether the cells are being charged enough. Check the breaks. Check if the break oil needs to be changed. Air pressure wash",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How long will it take for one servicing?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Usually the servicing takes a day (10am - 5pm) and in case of any change in wires or any tech upgrade, it will take longer.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "In case of vehicle breakdown, can I get towing services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, towing services are available for this vehicle. Please be noted that it is a paid service.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
  ],
};

export const piaggioFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "How many Piaggio three wheeler models are available at Turno?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno offers both the highest selling models of Piaggio 3 wheeler available currently in the market, i.e, Piaggio Ape E-xtra FX and Piaggio Ape E-xtra FX Max.",
      },
    },
    {
      "@type": "Question",
      name: "Which is the lowest-priced Piaggio three wheeler EV cargo in india?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can get Piaggio Ape E-xtra FX at a starting price of about 3.8 lakh rupees.",
      },
    },
    {
      "@type": "Question",
      name: "Which is the highest-priced Piaggio 3 wheeler electric auto in india?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The delivery van of Piaggio Ape FX is the highest priced ape Piaggio electric auto which costs around 4.33 lakh rupees.",
      },
    },
    {
      "@type": "Question",
      name: "How can I get a Piaggio ape auto price list in India?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is the leading destination for purchasing any electric three wheeler cargo in India. You can visit the Turno website for the best Piaggio ape auto Price.",
      },
    },
    {
      "@type": "Question",
      name: "Which body types are available with Piaggio ape cargo?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Piaggio ape cargos come in three different variants namely, Flatbed, Delivery Van and Pickup. However, Piaggio EVs are highly customisable. You can modify them according to your business needs.",
      },
    },
    {
      "@type": "Question",
      name: "Which are the popular Piaggio Piaggio ape pickups in india?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Currently the most popular Piaggio ape pickups that are available in India are Piaggio Ape E-xtra FX and Piaggio E-xtra FX Max.",
      },
    },
  ],
};

export const mahindraFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is the price of Mahindra Trucks?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of a Mahindra Trucks, such as the Mahindra Treo or other models, can vary depending on the specific variant, features, and location, you can get a Mahindra three wheeler cargo at a starting price of 3.63 lakh rupees. For accurate and up-to-date pricing information, you can log in to turno.club.",
      },
    },
    {
      "@type": "Question",
      name: "What is the mileage of Mahindra EV trucks?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The mileage or range of Mahindra EV trucks will depend on the particular model and battery capacity. Electric trucks from Mahindra are designed to offer sufficient range and can last up to 125 kms on a single charge. For precise details on the mileage of Mahindra EV trucks, please refer to the Turno website.",
      },
    },
    {
      "@type": "Question",
      name: "What is the loading capacity of Mahindra electric autos?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The loading capacity of Mahindra electric autos, such as the Mahindra Treo or other electric three-wheelers, can vary based on the model and configuration. These electric autos are purpose-built for commercial usage, and their loading capacities are generally tailored to meet the demands of businesses, logistics, and cargo transportation. To find out the specific loading capacity of a Mahindra electric auto, you can visit Turno.",
      },
    },
    {
      "@type": "Question",
      name: "What is the cost of Mahindra Treo per km?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cost of running a Mahindra Treo per kilometer (km) will depend on several factors, including electricity rates, battery capacity, driving conditions, and maintenance costs. Electric vehicles, including the Mahindra Treo, are known for their lower operational expenses compared to conventional petrol or diesel vehicles. The cost per km of a Mahindra Electric Vehicle can be as low as 75p/km.",
      },
    },
    {
      "@type": "Question",
      name: "How many km can a Mahindra EV Auto last?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Based on the model and variants Mahindra EVs can last up to 115 kms on a single charge. However, for accurate and practical range, you can call Turno. Turno EV experts are available 24*7 to answer all your electric load gadi related queries.",
      },
    },
  ],
};
export const etrioFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What are the different types of electric vehicles that Etrio Electric offers?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Etrio offers a wide range of electric goods vehicles including two models of electric autos - Etrio Touro Max+ and Etrio Touro Max++",
      },
    },
    {
      "@type": "Question",
      name: "What are the benefits of owning an Etrio Electric Vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The benefits of owning an Etrio cargo electric auto are advanced technology, the safest battery in the market, reliable performance, the widest variety of vehicles, and cost-effective operations.",
      },
    },
    {
      "@type": "Question",
      name: "Where can I buy an Etrio Electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is the best place to buy Etrio electric vehicles. Turno has many advantages over conventional buying options.",
      },
    },
    {
      "@type": "Question",
      name: "What is the warranty on an Etrio Electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Etrio three-wheeler autos come with a warranty of 3 years / 1,00,000 km whichever is earlier.",
      },
    },
    {
      "@type": "Question",
      name: "What are the charging options for Etrio Electric Vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The Etrio electric goods vehicles need a standard 15A wall outlet at a public charging station or one at home.",
      },
    },
    {
      "@type": "Question",
      name: "What are the maintenance requirements for Etrio Electric vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Etrio Electric vehicles require regular maintenance, like tyre rotation, brake inspection, battery inspection, and general inspection.",
      },
    },
  ],
};

export const eulerFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What are the different types of electric vehicles that Euler Electric offers?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Euler offers a wide range of electric goods vehicles including two models of electric autos - HiLoad PV and HiLoad DV.",
      },
    },
    {
      "@type": "Question",
      name: "What are the benefits of owning an Euler Electric Vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The benefits of owning an Euler cargo electric auto are advanced technology, the safest battery in the market, reliable performance, the widest variety of vehicles, and cost-effective operations.",
      },
    },
    {
      "@type": "Question",
      name: "Where can I buy an Euler Electric three wheeler?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is the best place to buy Euler electric vehicles. Turno has many advantages over conventional buying options.",
      },
    },
    {
      "@type": "Question",
      name: "How quickly does the Euler Electric vehicle charge?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Euler three-wheeler autos can get charged for 50 kilometers in 15 minutes.",
      },
    },
    {
      "@type": "Question",
      name: "What are the maintenance requirements for Euler 3 Wheeler?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Euler Electric vehicles require low maintenance, like tyre rotation, brake inspection, battery inspection, and general inspection.",
      },
    },
  ],
};
export const OSMFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What are the different types of electric vehicles that OSM Electric offers?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "OSM offers a wide range of electric goods vehicles, like OSM Rage+, OSM Rage+ Rapid, OSM Rage+ Frost, etc.",
      },
    },
    {
      "@type": "Question",
      name: "What are the benefits of owning an OSM Electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The benefits of owning an OSM goods electric auto are advanced technology, safest battery in the market, reliable performance, widest variety of vehicles, and cost-effective operations.",
      },
    },
    {
      "@type": "Question",
      name: "Where can I buy an OSM Electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is the best place to buy OSM load electric vehicles. Turno has many advantages over conventional buying options.",
      },
    },
    {
      "@type": "Question",
      name: "What is the warranty on an OSM Electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "OSM three-wheeler autos come with a warranty of 3-years / 50,000 km, whichever is earlier.",
      },
    },
    {
      "@type": "Question",
      name: "What are the charging options for OSM Electric vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The OSM electric goods vehicles have charging options such as Standard 15A wall outlet, Public charging station, and Home charger.",
      },
    },
    {
      "@type": "Question",
      name: "What are the safety features of OSM Electric vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The battery electric vehicles from OSM come with Hill Hold Assist, Rear View cameras, Electronic Stability Control, and GPS. Please note that these features may vary according to the models.",
      },
    },
    {
      "@type": "Question",
      name: "What are the maintenance requirements for OSM Electric vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "OSM Electric vehicles require regular maintenance, but the requirements are relatively low. The main maintenance requirements are tire rotation, brake inspection, battery inspection, and general inspection.",
      },
    },
  ],
};

export const articleSchema = {
  "@context": "https://schema.org",
  "@type": "Article",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": "https://www.turno.club/blog",
  },
  headline: "5 Advantages of Electric Vehicle and Commercial Electric Auto",
  description:
    "Using electric vehicles for business has many other benefits along with saving fuel cost. Read on to explore the benefits of Electric Vehicle.",
  image:
    "https://assets.turnoclub.com/developer/offer-turno-images/S3/blog/blog1.jpg",
  author: {
    "@type": "Organization",
    name: "Turno",
  },
  publisher: {
    "@type": "Organization",
    name: "Turno",
    logo: {
      "@type": "ImageObject",
      url: "/logoIcon.png",
    },
  },
  datePublished: "2023-02-09",
};

export const piaggioSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is the recommended service interval for first service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The recommended service interval for first service is  3 months or 5000 kms",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does the manufacturer provide any free services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, all  Piaggio Ape Electrik buyers will get 10 free services.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How do I schedule servicing for my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The dealer will give a reminder call 2-5 days ahead of the scheduled service date.  We strongly recommend getting the servicing done on time as if you can not turn up, it will result in lapsing your free service.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I get Road Side Assistance(RSA) for my Piaggio Ape Electrik?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, RSA is available. To arrange the service, you can directly call the dealer or call us for a faster resolution.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the steps of servicing my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The steps of vehicle servicing are as follows Charge the battery up to one block and then check with them multimeter to see whether the cells are being charged enough. Check the breaks. Check if the break oil needs to be changed. Air pressure wash",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How long will it take for one servicing?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It may take a max of 8 hours for complete servicing. However, if there are any other issues with the vehicle, it may take longer.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
  ],
};

export const mahindraSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is the recommended service interval for first service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The recommended service interval is 2000 kms or once in every 3 months.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does the manufacturer provide any free services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "First three services of your Mahindra Treo Zor along with the labor charges are free. After three servicings,  you will be charged for brake oil change and general battery check up.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How do I schedule servicing for my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "To book servicing of your vehicle, you can contact the service center directly. Otherwise, you can also reach out to the team and we can schedule it on your behalf.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I get Roadside Assistance(RSA) for my Mahindra Treo Zor?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, RSA is available. To arrange the service, you can directly call the dealer or call us for a faster resolution. Please note that the RSA services costs ₹1,260rs / year",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the steps of servicing my vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The steps of vehicle servicing are as follows- Charge the battery up to one block and then check with them multimeter to see whether the cells are being charged enough. Check the breaks. Check if the break oil needs to be changed. Air pressure wash",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How long will it take for one servicing?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Usually the servicing takes a day (10am - 5pm) and in case of any change in wires or any tech upgrade, it will take longer.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "In case of vehicle breakdown, can I get towing services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, towing services are available for this vehicle. Please be noted that it is a paid service.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
  ],
};

export const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Turno",
  url: "https://www.turno.club/",
  logo: "/logoIcon.png",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Bubble Pvt. Ltd., Attic Space, Chanakya 4th Floor",
    addressLocality:
      "2nd cross Nanjappa Reddy Layout, Koramangala 8th Block, Bengaluru",
    addressRegion: "Karnataka",
    postalCode: "560095",
    addressCountry: "India",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "contact",
    telephone: "8047482233",
    email: "sales@turno.club",
  },
  sameAs: [
    "https://www.youtube.com/channel/UCZDxqBUyszLlgIGMnc1pjMA",
    "https://www.instagram.com/turnoclub/",
    "https://www.facebook.com/turno.club/",
    "https://www.linkedin.com/company/turnoclub/",
  ],
};

export const webPageSpeakable = {
  "@context": "https://schema.org/",
  "@type": "WebPage",
  name: "Turno",
  url: "https://www.turno.club/",
  image: "/logoIcon.png",
  primaryImageOfPage: {
    "@type": "ImageObject",
    name: "https://assets.turnoclub.com/developer/offer-turno-images/S3/HomePageBannersDesktop/banner3.png",
  },
  speakable: {
    "@type": "SpeakableSpecification",
    xPath: [
      "/html/head/title",
      "/html/head/meta[@name='description']/@content",
    ],
  },
  url: "https://www.turno.club",
};

export const searchWebSite = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  url: "https://www.turno.club/",
  potentialAction: {
    "@type": "SearchAction",
    target: "https://www.turno.club/?q={search_term_string}",
    "query-input": "required name=search_term_string",
  },
};
